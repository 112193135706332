import React from 'react';
import { FaFacebook, FaInstagram, FaSpotify, FaX, FaYoutube } from "react-icons/fa6";

import '../styles/Footer.css';
import { FaFacebookSquare, FaInstagramSquare, FaYoutubeSquare } from 'react-icons/fa';

export default function Footer() {
  return (
    <div className='footer-wrapper'>
      {/* <div className='footer-top'>
        <img src={process.env.PUBLIC_URL + '/assets/outcaribbean_logo_white.png'} className='footer-logo'/>
        <div className='footer-input-container'>
          <input type='text' className='footer-input' placeholder='johndoe@gmail.com'/>
          <div className='footer-input-btn'>Submit</div>
        </div>
      </div> */}

      {/* <div className='footer-mid'>
        <div style={{display: 'flex'}}>
          <div className='footer-options'>
            <div className='footer-option'>Destinations</div>
            <div className='footer-option'>Contact</div>
            <div className='footer-option'>Privacy Policy</div>
            <div className='footer-option'>Cookies Policy</div>
          </div>

          <div className='footer-options' style={{marginLeft: '25px'}}>
            <div className='footer-option'>Terms of Service</div>
            <div className='footer-option'>Data Processing</div>
          </div>
        </div>

        <div className='footer-socials'>
          <div className='footer-social'>
            <FaSpotify size={20} color='#fff' style={{marginRight: '10px'}}/>
          </div>

          <div className='footer-social'>
            <FaYoutubeSquare size={20} color='#fff' style={{marginRight: '10px'}}/>
          </div>

          <div className='footer-social'>
            <FaFacebookSquare size={20} color='#fff' style={{marginRight: '10px'}}/>
          </div>

          <div className='footer-social'>
            <FaInstagramSquare size={20} color='#fff'/>
          </div>
        </div>
        
      </div> */}

      <div className='footer-bottom'>
        <div className='footer-bottom-text'>
          2024 OUTCARIBBEAN - ALL RIGHTS RESERVED
        </div>

        <img src={process.env.PUBLIC_URL + '/assets/footer-logo.png'} className='footer-logo' style={{height: '100px', position: 'absolute', right: 0, bottom: 0}}/>
      </div>
    </div>
  )
}
